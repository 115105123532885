import axios from 'axios';
import { LoaderComponent } from 'Components';
import { useRouter } from 'next/router';
import React, { FC } from 'react';

import { Page } from 'Pages/Page';
import { GET_PAGE } from 'Queries';

export async function getStaticPaths() {
  return { paths: [], fallback: true };
}

export async function getStaticProps({ params }) {
  const { pageSlug: slug = 'home' } = params;

  const baseUrl = `http://localhost:${process.env.PORT ?? 3000}`;

  const response = await axios.post(`${baseUrl}/api/graphql`, {
    query: GET_PAGE.loc?.source.body,
    variables: { slug },
  });

  return { props: { page: response.data.data.page }, revalidate: 30 };
}
interface PagePageProps {
  page?: any;
}

const PagePage: FC<PagePageProps> = ({ page }) => {
  const { isFallback } = useRouter();

  if (isFallback) return <LoaderComponent />;

  return <Page page={page} />;
};

export default PagePage;
