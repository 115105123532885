import { Text } from 'Components/Text';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { FC } from 'react';

import { Render } from 'Components/Render';

import './Page.scss';

interface PageProps {
  page?: any;
  // location: { pathname: string };
  // match: { params: { articleSlug: string } };
}

export const Page: FC<PageProps> = ({ page }) => {
  // console.log('Page', { page });
  const { asPath } = useRouter();
  // const [isLoading, setIsLoading] = useState(true);
  //
  // useEffect(() => {
  //   console.log('hello');
  //   setIsLoading(false);
  // }, []);

  if (!page) return null;

  return (
    <div data-page="page" data-visible={true}>
      <Head>
        <title>{page.title + ` | The Platform`}</title>

        <meta property="og:site_name" content="The Platform" />
        <meta property="og:url" content={`https://platformonline.uk/${asPath}`} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={page.title} />
        <meta property="og:description" content={page.summary} />
        <meta property="og:image" itemProp="image" content={page.featuredImageUrl} />
        <meta property="og:url" content={`https://platformonline.uk/${asPath}`} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@YourPlatformUK" />
      </Head>

      <div className="page">
        {page.visibleTitle && (
          <div className="container">
            <div className="row">
              <div className="col-md-1" />
              <div className="col-md-10">
                <div className="page-title" dangerouslySetInnerHTML={{ __html: page.visibleTitle }} />
              </div>
            </div>
          </div>
        )}

        <Render components={page.componentData} page={page} />

        {page.content && (
          <div className={`section`}>
            <div className="container">
              <Text text={page.content} isPage={true} page={page} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
